@use '../viewport' as viewport;
@use '../themes/default' as default-theme;

@include viewport.tablet {
  .loyalty-card-widget {
    position: relative;
    width: 400px;
    aspect-ratio: 4/2.5;
    border-radius: 10px;
    box-shadow: 1px 3px 4px 1px rgb(0 0 0 / 25%);
    background-color: default-theme.$light-gray-900;

    
    .background {
      img,
      .imgix img {
        width: 100%;
        border-radius: 10px;
        position: absolute;
        top: 0;
      }
    }

    .details {
      position: absolute;
      top: 0;
      width: calc(100% - 53px);
      height: 100%;
      padding: 0 27px;

      display: grid;
      grid-template-areas:
        'balance          emv-chip'
        'count-progress   count-progress'
        'customer-info    customer-info';
        

      .balance {
        grid-area: balance;
        display: flex;
        align-items: end;
        height: inherit;
        font-family: 'larabie';
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        color: #{default-theme.$bg};
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
        letter-spacing: 2px;
      }

      .mock-emv-chip {
        grid-area: emv-chip;
        display: flex;
        justify-content: end;
        align-items: end;

        img {
          width: 32px;
        }
      }

      .count-progress {
        grid-area: count-progress;
        display: grid;
        grid-auto-flow: column;
        column-gap: 5px;
        align-items: center;

        .count {
          background-color: #{default-theme.$material-gray-300};
          border-radius: 10px;
          height: 10px;
          box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.29);

          &.active {
            background-color: #{default-theme.$loyalty-point-on};
            border: 1px solid rgba(0, 0, 0, 0.21);
            box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.2), inset 0px -3px 3px rgba(0, 0, 0, 0.5);
          }
        }
      }

      .customer-info {
        grid-area: customer-info;
        font-family: 'kredit';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        color: #{default-theme.$bg};
        text-shadow: 1px 1px 1px #000000;
        line-height: 28px;
        text-transform: uppercase;
        letter-spacing: 2px;

        .name {
          -webkit-line-clamp: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
