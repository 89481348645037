@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .session-create-modal {
    width: 450px !important;

    .header {
      @include modal-header();
      .back-icon {
        cursor: pointer;
      }

      .title {
        text-align: center;
      }
    }

    .main-display {
      .collection-type-selector {
        margin-bottom: constants.$horizontal-gap-desktop;
        padding: 0 constants.$horizontal-gap-desktop;

        .title {
          display: none;
        }

        .collection-types {
          display: grid;
          gap: 16px;

          .collection-type {
            display: grid;
            grid-template-areas: 'collection-type-icon text . caret-right-icon';
            grid-auto-columns: min-content 1fr min-content;
            gap: 15px;
            padding: 20px 25px;
            border: 1px solid #{default-theme.$material-gray-300};
            border-radius: 8px;
            cursor: pointer;

            .collection-type-icon {
              grid-area: collection-type-icon;
              width: 42px;
            }

            .text {
              grid-area: text;
              align-items: center;
              @include typography.body-regular;
              color: #{default-theme.$restoplus-dark};
              display: flex;
              align-self: center;
              white-space: nowrap;
            }

            .caret-right.icon {
              grid-area: caret-right-icon;
              margin: auto 0;
            }
          }
        }
      }

      .table-selector {
        @include session-create-widget-title-and-image();
        padding: 0 constants.$horizontal-gap-desktop constants.$horizontal-gap-desktop;
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-areas:
          'title image'
          'table-list table-list';
        .table-list {
          @include modal-scrollable-content-with-inner-shadow();
          max-height: calc(100vh - 474px);
          grid-area: table-list;
          display: grid;
          grid-gap: 15px;
          .table {
            cursor: pointer;
            display: grid;
            grid-template-areas: 'name caret-right-icon';
            @include typography.body-regular;
            padding: 17px;
            border-radius: 8px;
            color: #{default-theme.$restoplus-dark};
            border: 1px solid #{default-theme.$material-gray-300};
            align-items: center;
            .name {
              grid-area: name;
              margin-right: 15px;
            }
            .caret-right.icon {
              grid-area: caret-right-icon;
              margin-left: auto;
            }
          }
        }
      }

      .collection-date-and-time-selector {
        @include session-create-widget-title-and-image();
        margin-bottom: 30px;
        padding: 0 constants.$horizontal-gap-desktop;
        display: grid;
        grid-template-areas:
          'title       image'
          'date-list   date-list';
        grid-template-columns: 1fr max-content;

        .third-party-delivery-banner {
          display: flex;
          flex-flow: row;
          grid-gap: 7px;
          width: 100%;
          background: #ffebcf;
          border-radius: 4px;
          padding: 16px;
          padding-right: 12px;
          box-sizing: border-box;
          margin-bottom: 8px;

          .message {
            @include typography.body-regular;
            margin: 0;
            > div {
              display: inline;
              @include typography.body-bold;
              color: #d67300;
            }
          }
          .arrow-link {
            cursor: pointer;
          }
        }

        .collection-dates-and-times {
          @include modal-scrollable-content-with-inner-shadow();
          max-height: calc(100vh - 498px);
          grid-area: date-list;
        }

        .holiday-info {
          @include typography.body-regular;
          margin-bottom: 8px;
          color: #{default-theme.$restoplus-dark};

          border-radius: 4px;
          padding: 10px;
          background: #{default-theme.$orange-light};
        }
      }

      .delivery-address-editor {
        .action-buttons {
          display: grid;
          grid-gap: 17px;
          padding: constants.$horizontal-gap-desktop;
          box-sizing: border-box;
          width: 100%;

          .start-order {
            background: #{default-theme.$restoplus-teal};
            color: #{default-theme.$bg};
            width: 100%;
            @include typography.button1;
            padding: 14px;

            &.disabled {
              opacity: 0.5;
              background: #{default-theme.$restoplus-teal} !important;
              color: #{default-theme.$bg} !important;
              border-color: unset !important;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
