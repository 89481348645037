@use "alert";
@use "aspect_ratio";
@use "buttons";
@use "card";
@use "checkbox";
@use "colors";
@use "coupon";
@use "daypicker";
@use "form";
@use "form_key_value_pair";
@use "form_address_search";
@use "dropdown";
@use "switch";
@use "form_select";
@use "imgix";
@use "time_picker";
@use "eta_picker";
@use "form_iso_date_picker";
@use "status_indicator";
@use "step_indicator";
@use "typography"as typography;
@use "progress";
@use "widgets/auth";
@use "widgets/backoffice";
@use "widgets/website";
@use "widgets/intro";
@use "widgets/document_edit_widget";
@use "widgets/store_select_widget";
@use "widgets/restaurant_website_info_edit_widget";
@use "widgets/pizza_edit_widget";
@use "widgets/deal_edit_widget";
@use "widgets/price_spec_edit_widget";
@use "widgets/restaurant_list_widget";
@use "widgets/store_card_view";
@use "widgets/store_list_widget";
@use "widgets/modifier_specs_edit_widget";
@use "widgets/pizza_settings_edit_widget";
@use "widgets/item_spec_list_widget";
@use "widgets/item_spec_view_widget";
@use "widgets/backoffice_category_list_widget";
@use "widgets/printer_queue_widget";
@use "widgets/store_edit_widget";
@use "widgets/coupon_card_view";
@use "widgets/coupon_edit_widget.scss";
@use "widgets/loyalty_card_modal";
@use "widgets/allergen-info-widget";
@use "widgets/uber_proof_of_delivery_widget";
@use 'widgets/item-summary-view-widget';
@use 'widgets/item-description-view-widget';
@use 'widgets/marketing-campaign-widget';

@use "./themes/default" as default-theme;

@use "backoffice/order_history_page" as backofficeOrderHistory;
@use "backoffice/store_timings_page";
@use "backoffice/delivery_settings_page";
@use "backoffice/payments_page";
@use "backoffice/menu_page";
@use "backoffice/order_settings_page";
@use "backoffice/printer_page";

@use "online_ordering/online_ordering_layout";
@use "online_ordering/online_ordering_banner.scss";
@use "online_ordering/update_address_modal";
@use "online_ordering/third_party_delivery_modal";

@use "restaurant_website/restaurant_website_layout.scss";
@use "restaurant_website/restaurant_website_header.scss";
@use "restaurant_website/restaurant_website_page";

@use "store_website/store_website_layout.scss";
@use "store_website/store_website_header.scss";
@use "store_website/store_website_page";

@use "table_booking/table_booking_otp";

@use "order_status_timeline/timeline_event";

@use "website/categories_page.scss";
@use "website/website_item_page.scss";
@use "website/item_actions.scss";
@use "website/order_success_page.scss";
@use "website/basic_item_widget";
@use "website/item_customization_modal";

// mobile
@use "./mobile/trading-hours-page-mobile";
@use "./mobile/bottom_nav_bar_mobile";
@use "./mobile/menu-page-mobile";
@use "./mobile/top-nav-bar-mobile";
@use "./mobile/cart-widget-mobile";
@use "./mobile/loyalty_card_page_mobile";
@use "./mobile/session-view-widget-mobile";
@use "./mobile/coupons_page_mobile";
@use "./mobile/offers-widget-mobile";
@use "./mobile/collection_type_widget_mobile";
@use "./mobile/order-success-page-mobile";
@use "./mobile/order-status-widget-mobile";
@use "./mobile/order-view-widget-mobile";
@use "./mobile/page_mobile";
@use "./mobile/modal_mobile";
@use "./mobile/deal-widget-mobile";
@use "./mobile/deal-pizza-list-widget-mobile";
@use "./mobile/half-and-half-widget-mobile";
@use "./mobile/half-and-half-pizza-list-widget-mobile";
@use "./mobile/order_history_page_mobile";
@use "./mobile/order-view-page-mobile";
@use "./mobile/badge_mobile";
@use "./mobile/delivery-address-edit-widget-mobile";
@use "./mobile/update-delivery-address-widget-mobile";
@use "./mobile/checkout-widget-mobile";
@use "./mobile/sweetalert_mobile";
@use "./mobile/basic-item-widget-mobile";
@use './mobile/add_to_cart_widget_mobile';
@use "./mobile/session-create-modal-mobile";
@use "./mobile/customer-login-flow-widget-mobile";
@use './mobile/modifier-widget-mobile';
@use "./mobile/loyalty_card_widget_mobile";
@use "./mobile/pizza-widget-mobile";
@use './mobile/pizza-customize-widget-mobile';
@use './mobile/header_widget_mobile';
@use './mobile/suburbs-list-widget-mobile';
@use './mobile/search_page_mobile';
@use './mobile/footer_widget_mobile';
@use "./mobile/table-booking-history-page-mobile";
@use "./mobile/table-booking-page-mobile";
@use "./mobile/form_select_pax_mobile";
@use "./mobile/form_single_select_pill_mobile";
@use './mobile/third-party-delivery-widget-mobile';
@use './mobile/third-party-delivery-services-modal-mobile';
@use './mobile/table_booking_success_page_mobile';
@use './mobile/sidebar_mobile';
@use './mobile/table-booking-edit-booking-details-widget-mobile';
@use './mobile/table_booking_edit_contact_details_widget_mobile';
@use './mobile/table_booking_summary_widget_mobile';
@use './mobile/toast_mobile';
@use "./mobile/spinner_mobile";
@use "./mobile/collection_time_selector_widget_mobile";
@use './mobile/item-list-widget-mobile';
@use './mobile/item-card-widget-mobile';
@use "./mobile/menu_search_widget_mobile";
@use './mobile/category_list_widget_mobile';
@use './mobile/store_closed_info_widget_mobile';
@use "./mobile/item_available_weekly_hours_widget_mobile";
@use './mobile/otp_for_mobile_number_request_widget_mobile';
@use './mobile/otp-verifier-widget-mobile';
@use './mobile/customer-display-name-update-widget-mobile';
@use './mobile/store_close_banner_widget_mobile';
@use './mobile/table_page_mobile';
@use './mobile/table_ordering_welcome_screen_widget_mobile';
@use './mobile/table_change_widget_mobile';
@use './mobile/table_ordering_qr_scanner_widget_mobile';
@use './mobile/store_maintenance_banner_widget_mobile';
@use './mobile/upsell_items_widget_mobile';
@use './mobile/uber-delivery-info-view-widget-mobile';
@use './mobile/customer-review-page-mobile';

// desktop
@use "./desktop/trading-hours-page-desktop";
@use "./desktop/menu-page-desktop";
@use "./desktop/top_nav_bar_desktop";
@use "./desktop/cart-widget-desktop";
@use "./desktop/loyalty_card_page_desktop";
@use "./desktop/session-view-widget-desktop";
@use "./desktop/coupons_page_desktop";
@use "./desktop/offers-widget-desktop";
@use "./desktop/collection_type_widget_desktop";
@use "./desktop/order_success_page_desktop";
@use "./desktop/order_status_widget_desktop";
@use "./desktop/order-view-widget-desktop";
@use "./desktop/page_desktop";
@use "./desktop/modal_desktop";
@use "./desktop/deal-widget-desktop";
@use "./desktop/deal-pizza-list-widget-desktop";
@use "./desktop/half-and-half-widget-desktop";
@use "./desktop/half-and-half-pizza-list-widget-desktop";
@use "./desktop/order_history_page_desktop";
@use "./desktop/order-view-page-desktop";
@use "./desktop/badge_desktop";
@use "./desktop/checkout-widget-desktop";
@use "./desktop/session-create-modal-desktop";
@use "./desktop/loyalty_card_widget_desktop";
@use "./desktop/pizza-widget-desktop";
@use "./desktop/basic-item-widget-desktop";
@use "./desktop/footer_widget_desktop";
@use './desktop/header_widget_desktop';
@use './desktop/suburbs-list-widget-desktop';
@use './desktop/delivery-address-edit-widget-desktop';
@use './desktop/update-delivery-address-widget-desktop';
@use './desktop/sweetalert_desktop';
@use './desktop/add_to_cart_widget_desktop';
@use './desktop/modifier-widget-desktop';
@use './desktop/pizza-customize-widget-desktop';
@use "./desktop/table-booking-history-page-desktop";
@use "./desktop/table-booking-page-desktop";
@use "./desktop/form_select_pax_desktop";
@use "./desktop/form_single_select_pill_desktop";
@use "./desktop/customer-login-flow-widget-desktop";
@use './desktop/third-party-delivery-widget-desktop';
@use './desktop/third-party-delivery-services-modal-desktop';
@use './desktop/table_booking_success_page_desktop';
@use './desktop/sidebar_desktop';
@use './desktop/table-booking-edit-booking-details-widget-desktop';
@use './desktop/table_booking_edit_contact_details_widget_desktop';
@use './desktop/table_booking_summary_widget_desktop';
@use './desktop/toast_desktop';
@use './desktop/spinner_desktop';
@use './desktop/collection_time_selector_widget_desktop';
@use './desktop/menu_search_widget_desktop';
@use './desktop/item-list-widget-desktop';
@use './desktop/item-card-widget-desktop';
@use './desktop/category_list_widget_desktop';
@use './desktop/store_closed_info_widget_desktop';
@use "./desktop/item_available_weekly_hours_widget_desktop";
@use './desktop/otp-for-mobile-number-verifier-widget-desktop';
@use './desktop/otp-verifier-widget-desktop';
@use './desktop/customer-display-name-update-widget-desktop';
@use './desktop/store_close_banner_widget_desktop';
@use './desktop/table_page_desktop';
@use './desktop/table_ordering_welcome_screen_widget_desktop';
@use './desktop/table_ordering_qr_scanner_widget_desktop';
@use './desktop/store_maintenance_banner_widget_desktop';
@use './desktop/upsell_items_widget_desktop';
@use './desktop/uber-delivery-info-view-widget-desktop';
@use './desktop/customer-review-page-desktop';

// Chivo
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-HairlineItalic.ttf') format('truetype');
//   font-weight: 100;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-Hairline.ttf') format('truetype');
//   font-weight: 100;
//   font-style: normal;
// }
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-MediumItalic.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-RegularItalic.ttf') format('truetype');
//   font-weight: 400;
//   font-style: italic;
// }
@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-ThinItalic.ttf') format('truetype');
//   font-weight: 200;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'Chivo';
//   src: url('../fonts/Chivo-Thin.ttf') format('truetype');
//   font-weight: 200;
//   font-style: normal;
// }

//Ocra
@font-face {
  font-family: 'Ocra';
  src: url('../fonts/OCRA Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ocra';
  src: url('../fonts/OCRA Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// SF Pro Display

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: 700;
  font-style: normal;
}

// IBM Plex

@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/IBMPlexMono-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'kredit';
  src: url('../fonts/kredit.ttf') format('truetype');
}

@font-face {
  font-family: 'larabie';
  src: url('../fonts/larabie.ttf') format('truetype');
}

* {
  touch-action: manipulation; //disables double tap to zoom on touchscreen devices
}

html,
body {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  overscroll-behavior-y: none;
  font-family: Chivo;
  @include typography.body-regular;
}

a {
  color: var(--link-fg);
  cursor: pointer;
}

b {
  font-weight: 500;
}

textarea {
  resize: none;
}

.component {
  background-color: var(--color-bg);
}

button {
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*Temporary css declaration to hide emulator warning until internal bug is fixed and `disableWarnings` flag can be passed in without typescript warnings*/
.firebase-emulator-warning {
  display: none;
}

.highlight::before {
  content: '';
  background: #d1ebff;
  position: absolute;
  left: 0;
  right: -2px;
  bottom: 2px;
  height: 16px;
  z-index: -1;
}
